import React from "react";
import { Tooltip } from "antd/lib";

interface RandomAvatarProps {
  name: string;
  src?: string;
  size?: number;
}

const getInitials = (name: string): string => {
  const initials = name
    .split(" ")
    .map(part => part[0])
    .join("");
  return initials.toUpperCase();
};

const getColor = (name: string): string => {
  // Generate a consistent color based on the name
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, 70%, 60%)`;
};

const RandomAvatar: React.FC<RandomAvatarProps> = ({ name, src, size = 32 }) => {
  const initials = getInitials(name);
  const color = getColor(name);

  return (
    <Tooltip title={name}>
      <div
        className="flex items-center justify-center rounded-full text-white"
        style={{
          width: size,
          height: size,
          backgroundColor: color,
          fontSize: size / 2.5,
        }}
      >
        {src ? <img src={src} alt={name} className="rounded-full w-full h-full object-cover" /> : initials}
      </div>
    </Tooltip>
  );
};

export default RandomAvatar;
