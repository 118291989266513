"use client";

import Heading from "@/app/components/Heading";
import MyCard from "@/app/components/MyCard";
import type { MyColumnType } from "@/app/components/MyTable";
import MyTable from "@/app/components/MyTable";
import { DateFormat } from "@/constant";
import { ellipsisString, formatThousands } from "@/helpers";
import useAxiosQuery from "@/hooks/useMyQuery";
import type { FundRaisingIF, FundRaisingIFRes } from "@/models/fundingRase";
import dayjs from "@/utils/dayjsConfig";
import { DownOutlined, LinkOutlined } from "@ant-design/icons";
import { Avatar, Button, Popover } from "antd/lib";
import Skeleton from "antd/lib/skeleton";
import Typography from "antd/lib/typography";
import Link from "antd/lib/typography/Link";
import { isEmpty } from "lodash";
import { useTranslations } from "next-intl";
import React, { useMemo } from "react";
import RandomAvatar from "./RandomAvatar";
import { useTimezoneContext } from "@/context/TimezoneContext";
import { useCurrencyContext } from "@/context/CurrencyContext";

const TokenInfo = React.memo(({ data }: { data?: FundRaisingIF }) => (
  <div className="flex items-center space-x-4">
    <div className="flex-shrink-0">
      {data?.logo_uri ? (
        <Avatar src={data?.logo_uri} alt={`${data?.name} logo`} className="rounded-full size-8" />
      ) : (
        <RandomAvatar name={data?.name || "Unknown"} />
      )}
    </div>
    <div className="flex flex-col">
      <div className="text-[14px] font-semibold text-black">{data?.name}</div>
    </div>
  </div>
));
TokenInfo.displayName = "TokenInfo";

const InvestorItem = React.memo(({ record }: { record?: FundRaisingIF }) => {
  const firstInvestors = record?.lead_investors?.[0];
  if (!firstInvestors) return <div className="text-left">-</div>;
  return (
    <div className={"flex items-center gap-2 justify-between"}>
      <div className="flex items-center gap-2 ">
        {!isEmpty(firstInvestors?.logo_uri) ? (
          <Avatar size={32} src={firstInvestors?.logo_uri} />
        ) : (
          <RandomAvatar name={(firstInvestors?.name ?? "").split(" ")?.[0] || "Unknown"} />
        )}
        <Typography.Text className="text-sm line-clamp-1 whitespace-nowrap" strong title={firstInvestors?.name}>
          {ellipsisString(firstInvestors?.name || "", 16)}
        </Typography.Text>
      </div>
      {record.other_investors?.length ? (
        <Popover
          placement={"bottomRight"}
          overlayStyle={{
            maxHeight: "300px",
            overflow: "auto",
          }}
          arrow={false}
          content={record.other_investors.map((item, index) => (
            <div key={index} className="flex items-center gap-1">
              {!isEmpty(item?.logo_uri) ? (
                <Avatar size={16} src={item?.logo_uri} />
              ) : (
                <RandomAvatar name={(item?.name ?? "Unknown").split(" ")?.[0]} size={16} />
              )}

              <Typography.Text strong title={item?.name}>
                {item?.name}
              </Typography.Text>
            </div>
          ))}
        >
          <Button type={"default"} className={"rounded-lg"} size={"small"}>
            <div className="flex items-center gap-1 min-w-[40px] justify-between">
              <Typography.Text>{`+${record.other_investors.length}`}</Typography.Text>
              <DownOutlined />
            </div>
          </Button>
        </Popover>
      ) : null}
    </div>
  );
});
InvestorItem.displayName = "InvestorItem";

function FundRaisingTable() {
  const t = useTranslations("home");
  const { timezone } = useTimezoneContext();
  const { currentCurrency } = useCurrencyContext();

  const { data: fundingRes, isLoading: isLoadingFunding } = useAxiosQuery<FundRaisingIFRes | undefined>({
    url: `/v1/klarda-insight/fundraising/table`,
    method: "get",
    params: {
      limit: 7,
    },
  });

  const columnsSignal = useMemo<MyColumnType<FundRaisingIF>[]>(
    () => [
      {
        title: t("Project"),
        key: "asset",
        dataIndex: "asset",
        width: 200,
        render: (_, record) => <TokenInfo data={record} />,
      },
      {
        title: t("Date"),
        width: 180,
        key: "stored_time",
        dataIndex: "stored_time",
        render: (_, record) => {
          const day = dayjs(record.stored_time).tz(timezone.utc);
          return <Typography.Text title={record.stored_time}>{day.format(DateFormat)}</Typography.Text>;
        },
      },
      {
        title: t("Raised"),
        key: "amount",
        dataIndex: "amount",
        width: 100,
        render: text =>
          text ? <Typography.Text strong>{formatThousands(text, currentCurrency)}</Typography.Text> : "-",
      },
      {
        title: t("Stage"),
        key: "round",
        dataIndex: "round",
        width: 150,
        render: (text, record) => (
          <div className="flex items-center w-[100px]">
            <Typography.Text className="whitespace-nowrap text-sm">{text ? text : "-"}</Typography.Text>
            <Link key={record.source} href={record.source} target={"_blank"} rel={"noopener noreferrer"}>
              <Button className="p-0" icon={<LinkOutlined />} type={"link"} />
            </Link>
          </div>
        ),
      },
      {
        title: t("Invertor"),
        key: "investors",
        dataIndex: "investors",
        width: 250,
        render: (_, record) => <InvestorItem record={record} />,
      },
      {
        title: t("Category"),
        key: "category",
        width: 220,
        dataIndex: "category",
        render: text => <Typography.Text title={text}>{ellipsisString(text, 30)}</Typography.Text>,
      },
    ],
    [t, timezone.utc, currentCurrency],
  );

  return (
    <MyCard
      className="h-full lg:col-span-8"
      styles={{ body: { padding: "8px 0px" } }}
      title={<Heading title={t("Fund_raising_round")} icon={null} />}
    >
      {isLoadingFunding ? (
        <Skeleton active paragraph={{ rows: 10 }} />
      ) : (
        <>
          <MyTable dataSource={fundingRes?.data?.result || []} columns={columnsSignal as any} scroll={{ x: 1100 }} />
        </>
      )}
    </MyCard>
  );
}

export default React.memo(FundRaisingTable);
