import type { GaugeConfig } from "@ant-design/plots";
import { G2, Gauge } from "@ant-design/plots";
import { theme } from "antd";
import Color from "colorjs.io";

const { registerShape, Util } = G2; // 自定义 Shape 部分

registerShape("point", "triangle-gauge-indicator", {
  draw(cfg, container) {
    // 使用 customInfo 传递参数
    const { indicator, defaultColor } = cfg.customInfo;
    const { pointer } = indicator;
    const group = container.addGroup(); // 获取极坐标系下画布中心点

    const center = (this as any).parsePoint({
      x: 0,
      y: 0,
    }); // 绘制指针

    if (pointer) {
      const { startAngle, endAngle } = Util.getAngle(cfg, (this as any).coordinate);
      const radius = (this as any).coordinate.getRadius();
      const midAngle = (startAngle + endAngle) / 2;
      const { x: x1, y: y1 } = Util.polarToCartesian(center.x, center.y, radius * 0.8, midAngle + Math.PI / 30);
      const { x: x2, y: y2 } = Util.polarToCartesian(center.x, center.y, radius * 0.8, midAngle - Math.PI / 30);
      const { x, y } = Util.polarToCartesian(center.x, center.y, radius * 0.93, midAngle);
      const path = [["M", x1, y1], ["L", x, y], ["L", x2, y2], ["Z"]]; // pointer

      group.addShape("path", {
        name: "pointer",
        attrs: {
          path,
          fill: defaultColor,
          ...pointer.style,
        },
      });
    }

    return group;
  },
});

const startColor = new Color("#FF4D4F");
const endColor = new Color("#52C41A");
const rangeColor = startColor.range(endColor);

export default function StatusGauge({
  score,
  max,
  title,
  content,
  className,
  isAutoColor,
}: {
  score: number;
  max: number;
  title?: string;
  content?: string;
  className?: string;
  isAutoColor?: boolean;
}) {
  const { token } = theme.useToken();

  const c = rangeColor(score / max).toString({ format: "hex" });

  const config: GaugeConfig = {
    percent: (score / max) * 0.9 + 0.05,
    height: 170,
    padding: 0,
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    range: {
      ticks: [0, 1],
      width: 12,
      color: ["l(0) 0:#F4664A 0.5:#FAAD14 1:#30BF78"],
    },
    indicator: {
      shape: "triangle-gauge-indicator",
      pointer: {
        style: {
          fill: c,
        },
      },
    },
    axis: false,
    statistic: {
      title: {
        formatter: () => title || "",
        offsetY: -35,
        style: () => ({
          fontSize: "25px",
          lineHeight: 1,
          color: c,
        }),
      },
      content: {
        offsetY: -8,
        style: {
          fontSize: "20px",
          color: isAutoColor ? c : token.colorTextSecondary,
        },
        formatter: () => content || "",
      },
    },
  };

  return <Gauge {...config} className={className} />;
}
