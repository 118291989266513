"use client";

import React from "react";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import MarketInsightCard from "@/app/components/MarketInsightCard";

const MarketSnapshot = React.memo(function MarketSnapshot() {
  return (
    <MarketInsightCard
      title="Market_snapshot"
      apiUrl={`${CLOUDFRONT_URL}/v1/market-insight/news/market-snapshot`}
      category="Market"
    />
  );
});

export default MarketSnapshot;
