import type { CSSProperties, PropsWithChildren } from "react";
import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

export default function ScrollableComponent({
  children,
  className,
  style,
}: PropsWithChildren & { className?: string; style?: CSSProperties }) {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const { events } = useDraggable(ref, { applyRubberBandEffect: true });
  return (
    <div {...events} className={className} style={style} ref={ref}>
      {children}
    </div>
  );
}
