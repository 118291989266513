export const DateFormat = "DD MMM YYYY";
export const HourFormat = "HH:mm";
export const DateTimeFormat = `${DateFormat} ${HourFormat}`;
export const TIMELINES_MARKET = [
  {
    label: "1D",
    format: "HH:mm",
    unit: "minute",
    step: 240,
  },
  {
    label: "1W",
    format: "DD MMM",
    unit: "day",
    step: 1,
  },
  {
    label: "1M",
    format: "DD MMM",
    unit: "day",
    step: 4,
  },
  {
    label: "3M",
    format: "DD MMM",
    unit: "day",
    step: 14,
  },
  {
    label: "6M",
    format: "DD MMM",
    unit: "month",
    step: 1,
  },
  {
    label: "1Y",
    format: "MMM 'YY",
    unit: "month",
    step: 2,
  },
] as TimelineType[];
export interface TimelineType {
  label: string;
  format: string;
  value?: number;
  duration?: number;
  interval?: number;
  unit?:
    | false
    | "minute"
    | "millisecond"
    | "second"
    | "hour"
    | "day"
    | "week"
    | "month"
    | "quarter"
    | "year"
    | undefined;
  step?: number;
  disabled?: boolean;
}
export const DurationChart = ["1M", "3M", "6M", "1Y", "ALL"];
export const MonthYearFormat = `MMM 'YY`;
export const threshold = 400;
export const SelectsDurationSignal = [
  {
    label: "15M",
    format: "HH:mm",
  },
  {
    label: "30M",
    format: "HH:mm",
  },
  {
    label: "1H",
    format: "HH:mm",
  },
  {
    label: "4H",
    format: "HH:mm",
  },
  {
    label: "6H",
    format: "MMM 'YY",
  },
  {
    label: "12H",
    format: "HH:mm",
  },
  {
    label: "1D",
    format: "HH:mm",
    disabled: false,
  },
] as TimelineType[];
