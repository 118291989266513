import React, { Fragment, useMemo, useState } from "react";
import { Button, Popover, Typography } from "antd";
import { useTranslations } from "next-intl";
import { DownOutlined } from "@ant-design/icons";

interface FilterOptionsProps {
  onOptionClick: (value: string) => void;
  selectedName: string;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({ onOptionClick, selectedName }) => {
  const t = useTranslations("home");
  const [popoverVisible, setPopoverVisible] = useState(false);

  const sourceFilterOptions = useMemo(
    () => [
      {
        title: <Typography.Text>{t("Top_Rank")}</Typography.Text>,
        group: [
          { label: t("Top_20"), value: "Top 20" },
          { label: t("Top_50"), value: "Top 50" },
          { label: t("Top_100"), value: "Top 100" },
          { label: t("Top_200"), value: "Top 200" },
        ],
      },
      {
        title: t("Hot_Lists"),
        group: [{ label: t("Top_trending"), value: "Top trending" }],
      },
      {
        title: t("Hot_Categories"),
        group: [
          { label: t("Meme"), value: "Meme" },
          { label: t("DeFi"), value: "DeFi" },
          { label: t("NFT"), value: "NFT" },
        ],
      },
      {
        title: t("Protocols"),
        group: [{ label: t("Protocols_TVL"), value: "Protocols TVL" }],
      },
      {
        title: t("Others"),
        group: [
          { label: t("Crypto_excluding_BTC"), value: "Crypto excluding BTC" },
          { label: t("Crypto"), value: "Crypto" },
          { label: t("Crypto_excluding_Top_3"), value: "Crypto excluding Top 3" },
          { label: t("Crypto_excluding_Stablecoins"), value: "Crypto excluding Stablecoins" },
          { label: t("Crypto_excluding_Top_3_and_Stablecoins"), value: "Crypto excluding Top 3 and Stablecoins" },
          { label: t("Currency"), value: "Currency" },
          { label: t("Blockchain_Infrastructure"), value: "Blockchain Infrastructure" },
          { label: t("Stablecoin"), value: "Stablecoin" },
          { label: t("Blockchain_Service"), value: "Blockchain Service" },
          { label: t("CeFi"), value: "CeFi" },
          { label: t("GameFi"), value: "GameFi" },
          { label: t("Social"), value: "Social" },
        ],
      },
    ],
    [t],
  );

  const handleOptionClick = (value: string) => {
    onOptionClick(value);
    setPopoverVisible(false);
  };

  const popoverContent = (
    <div className="flex flex-col space-y-2 overflow-y-auto max-h-[300px]">
      {sourceFilterOptions.map(parent => (
        <Fragment key={parent.title as string}>
          <Typography.Text strong className="ml-1">
            {parent.title}
          </Typography.Text>
          {parent.group.map(c => (
            <Typography.Text key={c.value} className="ml-3 cursor-pointer" onClick={() => handleOptionClick(c.value)}>
              {c.label}
            </Typography.Text>
          ))}
        </Fragment>
      ))}
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      open={popoverVisible}
      onOpenChange={visible => setPopoverVisible(visible)}
    >
      <Button type="default" className="flex items-center" onClick={() => setPopoverVisible(!popoverVisible)}>
        {selectedName} <DownOutlined />
      </Button>
    </Popover>
  );
};

export default FilterOptions;
