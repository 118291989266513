"use client";
import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "@/lib/axiosClient";
import dayjsConfig from "@/utils/dayjsConfig";

interface AxiosQueryParams {
  url: string;
  method?: "get" | "post" | "put" | "delete";
  body?: any;
  params?: Record<string, any>;
  axiosInstance?: typeof axiosClient;
  includeParamsInQueryKey?: boolean;
  enabled?: boolean;
}

const useAxiosQuery = <TData = unknown>(
  {
    url,
    method = "get",
    body,
    params,
    axiosInstance = axiosClient,
    includeParamsInQueryKey = false,
    enabled = true,
  }: AxiosQueryParams,
  options?: UseQueryOptions<TData, Error, TData, QueryKey>,
) => {
  // Generate the query key conditionally based on includeParamsInQueryKey
  const queryKey: QueryKey = includeParamsInQueryKey && params ? [url, params] : [url];

  // Define the query function
  const queryFn = async (): Promise<TData> => {
    const response = await axiosInstance({
      url,
      method,
      data: body,
      params,
    });
    return response.data;
  };

  // Return the useQuery hook using object syntax
  return {
    ...useQuery({
      queryKey,
      queryFn,
      enabled,
      ...options,
    }),
    fetchTime: dayjsConfig(),
  };
};

export default useAxiosQuery;
