"use client";
import { FaIcon } from "@/app/components/faIcon";
import MyCard from "@/app/components/MyCard";
import { DateTimeFormat } from "@/constant";
import { formatThousands } from "@/helpers";
import useAxiosQuery from "@/hooks/useMyQuery";
import type { IIDO } from "@/models/marketEvent";
import dayjs from "@/utils/dayjsConfig";
import { Avatar, Divider, Dropdown, Image, Skeleton, Space, Tag, Typography } from "antd/lib";
import { useTranslations } from "next-intl";
import React, { useMemo } from "react";
import { useTimezoneContext } from "@/context/TimezoneContext";
import { useCurrencyContext } from "@/context/CurrencyContext";

const start = dayjs();
const end = dayjs(dayjs().add(+30, "d"));

export const MarketICOIDO = () => {
  const t = useTranslations("home");
  const { timezone } = useTimezoneContext();
  const { currentCurrency } = useCurrencyContext();
  const MediaIconMap = useMemo(
    () => ({
      twitter: <FaIcon name="twitter" className="size-4 text-[#1D9BF0]" />,
      telegram: <FaIcon name="telegram" className="size-5 text-[#27A7E7]" />,
      whitepaper: <FaIcon name="whitepaper" className="size-4 text-[#bfbfbf]" />,
      web: <FaIcon name="web" className="size-4 text-[#bfbfbf]" />,
    }),
    [],
  );

  return (
    <MarketEvent
      height={500}
      api={"ido-calendar/ido-by-date-range"}
      render={(i: IIDO) => (
        <>
          <Tag>{dayjs(i.start_date).tz(timezone.utc).format(DateTimeFormat)}</Tag>
          <div className="flex items-center gap-2">
            <Avatar
              size={24}
              src={<Image src={i.token_icon} alt="token" style={{ width: 28, height: 28 }} preview={false} />}
            />
            <Typography.Link strong href={i.web} target={"_blank"} rel={"noopener noreferrer"}>
              {i.token_name}
            </Typography.Link>
            <Typography.Text type={"secondary"}>{i.token_symbol}</Typography.Text>
          </div>
          <Space direction={"vertical"} className={"w-full gap-1 mt-2"}>
            {[
              {
                title: t("Links"),
                value: (
                  <div className={"flex gap-2"}>
                    {[
                      { name: "web", url: i.web },
                      { name: "telegram", url: i.telegram },
                      { name: "twitter", url: i.twitter },
                      { name: "whitepaper", url: i.white_paper },
                    ].map((item, i) =>
                      item.url ? (
                        <Typography.Link key={i} href={item.url} target="_blank">
                          {(MediaIconMap as any)[item.name] || <FaIcon className="text-black size-4" name="global" />}
                        </Typography.Link>
                      ) : null,
                    )}
                  </div>
                ),
              },
              {
                title: t("Category"),
                value: i.category,
              },
              {
                title: t("Raise"),
                value: formatThousands(i.total_raise, currentCurrency),
              },
              {
                title: t("Launchpad"),
                value: i.launchpad_list?.length ? (
                  <Space wrap>
                    {i.launchpad_list.slice(0, i.launchpad_list.length <= 2 ? 2 : 1).map((l, index) => (
                      <Typography.Text key={index} type={!l.Website ? "secondary" : undefined}>
                        {l.Launchpad}
                      </Typography.Text>
                    ))}
                    {i.launchpad_list.length > 2 && (
                      <Dropdown
                        menu={{
                          items: i.launchpad_list.slice(1).map(l => ({
                            key: l.Website,
                            label: <Typography.Text>{l.Launchpad}</Typography.Text>,
                          })),
                        }}
                      >
                        <Tag>{`+${i.launchpad_list.length - 1}`}</Tag>
                      </Dropdown>
                    )}
                  </Space>
                ) : (
                  "--"
                ),
              },
            ].map((r, index) => (
              <div key={r.title}>
                {index !== 0 && <Divider className={"my-2"} />}
                <div className="flex items-center justify-between w-full">
                  <Typography.Text type={"secondary"}>{r.title}</Typography.Text>
                  {typeof r.value === "string" ? <Typography.Text strong>{r.value}</Typography.Text> : r.value}
                </div>
              </div>
            ))}
          </Space>
        </>
      )}
    />
  );
};

function MarketEvent<T>({
  height,
  api,
  render,
}: {
  height: number;
  api: string;
  render: (data: T) => React.ReactNode;
}) {
  const { data: dataRes, isLoading } = useAxiosQuery<{ data: { data: T[] } } | undefined>({
    url: `/v1/${api}`,
    method: "get",
    params: {
      limit: 7,
      start_time: start,
      end_time: end,
    },
  });

  const allData = useMemo<T[] | undefined>(() => dataRes && dataRes?.data?.data, [dataRes]);

  return isLoading ? (
    Array(5)
      .fill("")
      .map((_, i) => <Skeleton key={i} active />)
  ) : (
    <>
      <div
        style={{ minHeight: height, maxHeight: height }}
        className="w-full mt-3 pr-0.5 overflow-y-auto flex flex-col gap-4"
      >
        {!!allData &&
          allData.map((d: T, index) => (
            <MyCard key={index} size={"small"}>
              <Space className="w-full" direction="vertical">
                {render(d)}
              </Space>
            </MyCard>
          ))}
      </div>
    </>
  );
}

function CalendarCard() {
  const t = useTranslations("home");

  return (
    <MyCard title={t("Calendar")} className="h-full" styles={{ body: { padding: "8px" } }}>
      <MarketICOIDO />
    </MyCard>
  );
}

export default React.memo(CalendarCard);
