"use client";
import React from "react";
import { useTranslations } from "next-intl";
import MyCard from "@/app/components/MyCard";
import { Typography } from "antd/lib";
import Link from "next/link";
import { useLanguageContext } from "@/context/LanguageContext";
import { Button } from "antd";

const PartnerSection: React.FC = () => {
  const t = useTranslations("home");
  const { language } = useLanguageContext();

  const sections = [
    { label: "For Business", title: "Klarda Partners", href: `/${language}/registration-portal?type=klarda_partner` },
    {
      label: "For Investor",
      title: "Klarda Investments",
      href: `/${language}/registration-portal?type=invest_in_klarda`,
    },
    { label: "For Talents", title: "Klarda Members", href: `/${language}/registration-portal?type=klarda_partner` },
    {
      label: "For Startup",
      title: "Have a project in your mind?",
      href: `/${language}/registration-portal?type=new_project`,
    },
  ];
  return (
    <MyCard
      styles={{
        body: { padding: "48px 0px", gap: 48, display: "flex", flexDirection: "column" },
      }}
    >
      <div className="flex flex-col gap-4">
        <Typography.Title className="mb-0">{t("Do_not")}</Typography.Title>
        <Typography.Text type="secondary" className="text-lg">
          {t("Let_create")}
        </Typography.Text>
        <Button
          className="flex items-center gap-1 w-fit"
          size="large"
          href="https://inside.klarda.com/partners"
          target="_blank"
        >
          {t("Detail_KPR")} <span className="font-bold">→</span>
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        <Typography.Text type="secondary" className="text-xl">
          {t("Which_section")}
        </Typography.Text>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 md:gap-8">
          {sections.map((section, index) => (
            <Link
              href={section.href}
              key={index}
              className="flex items-center justify-between bg-gray-100 p-6 rounded-lg shadow-md hover:bg-gray-200 cursor-pointer"
            >
              <div>
                <Typography.Text className="text-blue-600">{section.label}</Typography.Text>
                <Typography.Title level={5} className="my-0">
                  {section.title}
                </Typography.Title>
              </div>
              <span className="text-xl font-bold">→</span>
            </Link>
          ))}
        </div>
      </div>
    </MyCard>
  );
};

export default PartnerSection;
