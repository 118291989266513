import React, { useEffect, useMemo, useRef, useState } from "react";
import { Spin, Typography } from "antd/lib";
import useAxiosQuery from "@/hooks/useMyQuery";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import { usePrice } from "@/context/PriceContext";
import { ChartArea } from "./charts/ChartArea";
import { formatThousands } from "@/helpers";
import { useCurrencyContext } from "@/context/CurrencyContext";

interface ChartResponse {
  result: {
    date?: string[];
    price?: number[];
  };
}

export const SmallChart: React.FC<{
  change: number;
  symbol: string;
  price?: number;
  duration?: string;
  height?: number;
  durationFormat?: string;
  isOpenShare?: boolean;
}> = React.memo(({ symbol, height, change, price = 0, duration = "1D", durationFormat = "HH:mm", isOpenShare }) => {
  const { getPrice } = usePrice();
  const { currentCurrency } = useCurrencyContext();

  const [flash, setFlash] = useState(false);
  const prevPriceRef = useRef<number | undefined>(undefined);
  const [pointPosition, setPointPosition] = useState({ left: 0, top: 0 });

  const [previousChartData, setPreviousChartData] = useState<{ date?: string[]; price?: number[] }>({
    date: [],
    price: [],
  });
  const [previousMaxPrice, setPreviousMaxPrice] = useState(0);
  const [previousMinPrice, setPreviousMinPrice] = useState(0);

  const currentPrice = useMemo(() => getPrice(symbol)?.price ?? price, [getPrice, price, symbol]);

  const { data: chartData, isLoading: isChartLoading } = useAxiosQuery<ChartResponse>({
    url: `${CLOUDFRONT_URL}/v1/market-movement/chart-history/${symbol}/${duration}`,
    method: "get",
  });

  const priceDataArray = useMemo(() => {
    const dates = chartData?.result?.date || previousChartData.date || [];
    const prices = chartData?.result?.price || previousChartData.price || [];
    return dates.map((date, index) => ({ date, price: prices[index] }));
  }, [chartData, previousChartData]);

  const [maxPrice, minPrice] = useMemo(() => {
    if (!priceDataArray.length) return [previousMaxPrice, previousMinPrice];
    const prices = priceDataArray.map(item => item.price);
    return [Math.max(...prices), Math.min(...prices)];
  }, [priceDataArray, previousMaxPrice, previousMinPrice]);

  const isDown = useMemo(() => change < 0, [change]);

  useEffect(() => {
    if (!isChartLoading && chartData?.result) {
      setPreviousChartData(chartData.result);
      const [max, min] = priceDataArray.map(item => item.price);
      setPreviousMaxPrice(max);
      setPreviousMinPrice(min);
    }
  }, [isChartLoading, chartData, priceDataArray]);

  useEffect(() => {
    if (currentPrice !== undefined && prevPriceRef.current !== undefined) {
      if (currentPrice !== prevPriceRef.current) {
        setFlash(true);
        const timeout = setTimeout(() => setFlash(false), 1000);
        return () => {
          clearTimeout(timeout);
          prevPriceRef.current = currentPrice;
        };
      }
    }
    prevPriceRef.current = currentPrice;
  }, [currentPrice]);

  const updatePointPosition = () => {
    const annotationElements = document.querySelectorAll(".g2-html-annotation, .g2-html-annotation-share");
    annotationElements.forEach(element => {
      const chartPoint = element.querySelector(".chart-point");
      if (isOpenShare) {
        element.classList.replace("g2-html-annotation", "g2-html-annotation-share");
      } else {
        element.classList.replace("g2-html-annotation-share", "g2-html-annotation");
      }
      if (chartPoint) {
        const computedStyle = window.getComputedStyle(element);
        setPointPosition({
          left: parseFloat(computedStyle.left),
          top: parseFloat(computedStyle.top),
        });
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(updatePointPosition, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpenShare, symbol, priceDataArray]);

  const lastPoint = priceDataArray[priceDataArray.length - 1];

  if (!priceDataArray.length) {
    return (
      <div className="text-center text-gray-500">
        <Typography.Text>No Data</Typography.Text>
      </div>
    );
  }

  return (
    <div className="w-full relative">
      {isChartLoading && (
        <div className="absolute inset-0 flex justify-center items-center z-20">
          <Spin />
        </div>
      )}

      <div className={`w-full relative chart-container ${isChartLoading ? "opacity-50" : "opacity-100"}`}>
        <ChartArea
          height={height}
          lastPoint={lastPoint}
          data={priceDataArray}
          maxPrice={maxPrice}
          minPrice={minPrice}
          isDown={isDown}
          durationFormat={durationFormat}
        />
        <div
          className={`absolute ${
            flash ? (currentPrice > (prevPriceRef?.current ?? 0) ? "bg-green-500" : "bg-red-500") : "bg-transparent"
          } rounded-full`}
          style={{
            width: 10,
            height: 10,
            left: `${pointPosition.left}px`,
            top: `${pointPosition.top}px`,
            transform: "translate(-50%, -50%)",
            transition: "background-color 0.3s ease",
          }}
        />
        <div
          className="annotation-tag orange absolute z-10"
          style={{
            left: `${pointPosition.left + 12}px`,
            top: `${pointPosition.top - 10}px`,
          }}
        >
          {formatThousands(currentPrice, currentCurrency)}
        </div>
      </div>
    </div>
  );
});

SmallChart.displayName = "SmallChart";
