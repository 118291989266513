"use client";
import React, { useEffect, useMemo, useState } from "react";
import { Divider, Popover, Skeleton, Typography } from "antd/lib";
import useAxiosQuery from "@/hooks/useMyQuery";
import FilterOptions from "@/app/components/FilterOptions";
import { formatPercent, formatThousands, NewsFromProjectCategory, sanitizeHtmlContent } from "@/helpers";
import { motion } from "framer-motion";
import type { INewsResponse, NewsResponseCDN } from "@/models/news";
import { CLOUDFRONT_URL, IMAGE_SHARE_CDN } from "@/constant/apiPath";
import NewsInfo from "@/app/components/NewsInfo";
import imageBlog from "@/asset/images/image-blog.png";
import imageExample from "@/asset/images/image-example.png";
import MyCard from "@/app/components/MyCard";
import TokenPrice from "@/app/components/TokenPrice";
import { usePrice } from "@/context/PriceContext";
import LinkToKlardaApp from "./LinkToKlardaApp";
import { MoreOutlined } from "@ant-design/icons";
import { useTranslations } from "next-intl";
import Link from "next/link";
import "../styles/top_flops.scss";
import { useRouter } from "next/navigation";
import NotSupportedPopover from "./NotSupportedPopover";
import { useCurrencyContext } from "@/context/CurrencyContext";
import { useLanguageContext } from "@/context/LanguageContext";

interface TopsFlopsItem {
  id: number;
  name: string;
  symbol: string;
  ticker: string;
  price: number;
  change: number;
  isPositive: boolean;
  logo_uri: string;
}

interface ApiResponse {
  tops: TopsFlopsItem[];
  flops: TopsFlopsItem[];
}

const ArticleCard = React.memo(
  ({ article, onClickNews }: { article: INewsResponse; onClickNews?: (news: INewsResponse) => void }) => {
    const title = sanitizeHtmlContent(article.title || article.description || "");
    const { language } = useLanguageContext();

    const imageUrl = article?.is_klarda
      ? `${IMAGE_SHARE_CDN}${article?.thumbnail}`
      : article.thumbnail || (article.klarda_categories?.includes(NewsFromProjectCategory) ? imageBlog : imageExample);
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="flex items-center space-x-4"
        onClick={() => onClickNews && onClickNews(article)}
      >
        <Link
          href={article?.is_klarda ? `/${language}/news/blogs/${article?.link}` : `/${language}/news/${article?.id}`}
          passHref
        >
          <img
            onError={e => {
              e.currentTarget.src = "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png";
            }}
            src={imageUrl as string}
            alt={title}
            width={100}
            className="rounded h-[90px] min-w-[110px]"
          />
        </Link>
        <div className="flex flex-col w-full gap-2">
          <Typography.Link
            title={title}
            href={article.is_klarda ? `/${language}/news/blogs/${article.link}` : `/${language}/news/${article.id}`}
          >
            <Typography.Title level={5} className="line-clamp-2 text-sm">
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </Typography.Title>
          </Typography.Link>
          <NewsInfo news={article} />
        </div>
      </motion.div>
    );
  },
);
ArticleCard.displayName = "ArticleCard";
const TopsFlopsItemCard = React.memo(({ item }: { item: TopsFlopsItem }) => {
  const { getPrice } = usePrice();
  const { currentCurrency } = useCurrencyContext();
  const { language } = useLanguageContext();
  const router = useRouter();
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
      className="tops-flops-item-card flex items-center justify-between px-2 rounded mycard-hover"
      onClick={() => router.push(`/${language}/markets/${item.symbol}`)}
    >
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <img src={item.logo_uri} alt={`${item.name} logo`} width={32} height={32} className="rounded-full" />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold text-black" style={{ fontSize: "14px" }}>
            {item.name} <span className="text-gray-300 font-normal">{item.ticker}</span>
          </div>
          {getPrice(item.symbol)?.price ? (
            <TokenPrice initPrice={item.price} symbol={item.symbol} className="font-normal text-black" />
          ) : (
            <div className="font-normal text-black">{formatThousands(item.price, currentCurrency)}</div>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div className="change rounded-lg">
          <Typography.Text strong type={item.change > 0 ? "success" : "danger"} className="min-w-[60px]">
            {formatPercent(item.change)}%
          </Typography.Text>
        </div>
        <div className="hidden md:flex space-x-2">
          <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
            <button className="buy bg-[#32CD32] text-white px-3 py-1 rounded text-[14px]">B</button>
          </Popover>
          <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
            <button className="sell bg-red-500 text-white px-3 py-1 rounded text-[14px]">S</button>
          </Popover>
        </div>
        <div className="relative group md:hidden">
          <button className="bg-gray-200 text-gray-600 px-3 py-1 rounded">
            <MoreOutlined />
          </button>
          <div className="absolute right-0 p-2 mt-2 w-28 bg-white border border-gray-300 rounded-lg shadow-lg hidden group-hover:block z-10">
            <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
              <button className="buy bg-[#32CD32] text-white px-3 py-1 rounded text-[14px]">B</button>
            </Popover>
            <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
              <button className="sell bg-red-500 text-white px-3 py-1 rounded text-[14px]">S</button>
            </Popover>
          </div>
        </div>
      </div>
    </motion.div>
  );
});
TopsFlopsItemCard.displayName = "TopsFlopsItemCard";
const KlardaSearch: React.FC = () => {
  const t = useTranslations("home");
  const [selectedName, setSelectedName] = useState<string>("Top 50");
  const { language } = useLanguageContext();

  const { data: response, isLoading } = useAxiosQuery<{ data: ApiResponse }>({
    url: "/v1/market-movement/landing_page/heat-map/tops-flops/v2",
    params: { duration: "1D", is_watchlist: false, is_portfolio: false, name: selectedName },
    includeParamsInQueryKey: true,
  });

  const { data: newsData, isLoading: isLoadingNews } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news/is-klarda`,
    method: "get",
  });
  const [sortedTopsFlops, setSortedTopsFlops] = useState<Array<TopsFlopsItem>>([]);

  const articles = useMemo(() => newsData?.news || [], [newsData?.news]);

  useEffect(() => {
    if (response?.data) {
      const combined = [...response.data.tops, ...response.data.flops];
      const sortedData = combined.sort((a, b) => b.change - a.change);

      const topGainers = sortedData.slice(0, 3);
      const topLosers = sortedData.slice(-3);

      setSortedTopsFlops([...topGainers, ...topLosers]);
    }
  }, [response]);

  const handleOptionClick = (value: string) => {
    setSelectedName(value);
  };

  return (
    <MyCard title="KLARDA RESEARCH" extra={<LinkToKlardaApp />}>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <MyCard title={t("analysis_and_opinion")} className="h-full" size="small">
            <div className={`mb-6 grid grid-cols-1 ${articles.length > 3 || isLoadingNews ? "md:grid-cols-2" : ""}`}>
              <div className="flex">
                <div className="space-y-4">
                  {isLoadingNews
                    ? Array.from({ length: 3 }).map((_, index) => <Skeleton key={index} className="w-[300px]" active />)
                    : articles.slice(0, 3).map((article, index) => (
                        <div key={article.id} className="mycard-hover">
                          <ArticleCard article={article} />
                          {index < 2 && <Divider />}
                          {index === 2 && articles.length > 3 && <Divider className="block lg:hidden" />}
                        </div>
                      ))}
                </div>
                {articles.length > 3 && <Divider type="vertical" className="h-full hidden lg:block" />}
              </div>
              {isLoadingNews ? (
                <div className="space-y-4">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton key={index} className="w-[300px]" active />
                  ))}
                </div>
              ) : (
                articles.length > 3 && (
                  <div className="space-y-4">
                    {articles.slice(3, 6).map((article, index) => (
                      <div key={article.id} className="mycard-hover">
                        <ArticleCard article={article} />
                        {index < 2 && <Divider />}
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
            <LinkToKlardaApp
              isOnlyText
              label={t("View_more_articles")}
              url={`/${language}/news/search?category=Klarda%20Insight`}
              className="pt-4 block"
            />
          </MyCard>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <MyCard
            title={t("tops_flops")}
            className="h-full"
            size="small"
            extra={<FilterOptions selectedName={selectedName} onOptionClick={handleOptionClick} />}
            styles={{ body: { padding: "7px 3px" } }}
          >
            <div className="space-y-[3px]">
              {isLoading ? (
                <Skeleton active paragraph={{ rows: 8 }} />
              ) : (
                <>
                  {sortedTopsFlops.map((item, index) => (
                    <TopsFlopsItemCard key={item.id + index} item={item} />
                  ))}
                  <LinkToKlardaApp isOnlyText label={t("More_token")} url="/markets" className="pt-4 block" />
                </>
              )}
            </div>
          </MyCard>
        </div>
      </div>
    </MyCard>
  );
};

export default KlardaSearch;
