"use client";
import React, { useEffect, useMemo, useState } from "react";
import { Popover, Skeleton, Typography } from "antd/lib";
import { motion } from "framer-motion";
import useAxiosQuery from "@/hooks/useMyQuery";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import { formatPercent } from "@/helpers";
import { SmallChart } from "@/app/components/SmallChart";
import TokenPrice from "@/app/components/TokenPrice";
import { useTranslations } from "next-intl";
import { MoreOutlined } from "@ant-design/icons";
import MyCard from "@/app/components/MyCard";
import LinkToKlardaApp from "@/app/components/LinkToKlardaApp";
import { useRouter } from "next/navigation";
import NotSupportedPopover from "./NotSupportedPopover";
import { useLanguageContext } from "@/context/LanguageContext";

interface TokenInfo {
  _id: string;
  name: string;
  symbol: string;
  price: number;
  updated_at: string;
  logo_uri: string;
  price_change: {
    change_1D: {
      usd: number;
      percentage: number;
    };
  };
}

const RenderTable = ({ data, onHoverSymbol }: { data: TokenInfo[]; onHoverSymbol: (symbol: string) => void }) => {
  const router = useRouter();
  const { language } = useLanguageContext();

  return (
    <div className="flex flex-col gap-1 mt-2">
      {data.map(item => (
        <motion.div
          key={item._id}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          className="flex items-center justify-between px-2 rounded cursor-pointer mycard-hover py-1"
          onClick={() => router.push(`/${language}/markets/${item.symbol}`)}
          onMouseEnter={() => onHoverSymbol(item.symbol)}
        >
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img src={item.logo_uri} alt={`${item.name} logo`} width={32} height={32} className="rounded-full" />
            </div>
            <Typography.Text strong>{item.name}</Typography.Text>
          </div>
          <div className="flex items-center space-x-2 text-right">
            <TokenPrice initPrice={item.price} symbol={item.symbol} className="text-right" />
            <Typography.Text
              type={item.price_change.change_1D.percentage > 0 ? "success" : "danger"}
              className="min-w-[60px]"
            >
              {formatPercent(item.price_change.change_1D.percentage)}%
            </Typography.Text>

            <div className="hidden md:flex space-x-2">
              <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
                <button className="buy bg-[#32CD32] text-white px-3 py-1 rounded text-[14px]">B</button>
              </Popover>
              <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
                <button className="sell bg-red-500 text-white px-3 py-1 rounded text-[14px]">S</button>
              </Popover>
            </div>

            <div className="relative group md:hidden">
              <button className="bg-gray-200 text-gray-600 px-3 py-1 rounded">
                <MoreOutlined />
              </button>
              <div className="absolute right-0 p-2 mt-2 w-28 bg-white border border-gray-300 rounded-lg shadow-lg hidden group-hover:block z-10">
                <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
                  <button className="buy bg-[#32CD32] text-white px-3 py-1 rounded text-[14px]">B</button>
                </Popover>
                <Popover content={<NotSupportedPopover />} trigger="hover" title={null}>
                  <button className="sell bg-red-500 text-white px-3 py-1 rounded text-[14px]">S</button>
                </Popover>
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

const TrendingComponent: React.FC = React.memo(() => {
  const t = useTranslations("home");
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null);

  const { data: trendingData, isLoading: isTrendingLoading } = useAxiosQuery<TokenInfo[]>({
    url: `${CLOUDFRONT_URL}/v1/market-movement/token-info/public/trending`,
    method: "get",
  });

  useEffect(() => {
    if (trendingData && trendingData.length > 0) {
      setSelectedSymbol(trendingData[0].symbol);
    }
  }, [trendingData]);

  const trendingTable = useMemo(
    () => <RenderTable onHoverSymbol={setSelectedSymbol} data={trendingData?.slice(0, 6) || []} />,
    [trendingData],
  );

  const selectedToken = useMemo(
    () => trendingData?.find(token => token.symbol === selectedSymbol),
    [selectedSymbol, trendingData],
  );
  return (
    <MyCard
      title={t("Market_movement")}
      className="w-full"
      size="default"
      styles={{ body: { display: "flex", alignItems: "center", padding: 8 }, header: { border: "none" } }}
      extra={<LinkToKlardaApp isOnlyText url={"/markets/"} />}
    >
      {isTrendingLoading ? (
        <Skeleton active paragraph={{ rows: 6 }} />
      ) : (
        <div className="w-full">
          <div className="w-full p-3 bg-[#F5F5F5] shadow rounded-lg">
            {selectedSymbol && (
              <div className="mb-4 bg-white">
                <SmallChart
                  change={selectedToken?.price_change?.change_1D?.percentage ?? 0}
                  price={selectedToken?.price}
                  symbol={selectedSymbol}
                  height={280}
                />
              </div>
            )}
            {trendingTable}
          </div>
        </div>
      )}
    </MyCard>
  );
});

TrendingComponent.displayName = "TrendingComponent";
export default TrendingComponent;
