"use client";
import Color from "colorjs.io";
import { useTranslations } from "next-intl";
import React, { useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import StatusGauge from "@/app/components/charts/statusGauge";
import Heading from "@/app/components/Heading";
import useAxiosQuery from "@/hooks/useMyQuery";
import { Skeleton, Typography } from "antd";
import MyCard from "@/app/components/MyCard";

type IGauge = {
  value: number;
  type: string;
};

interface IStatisticGauge {
  now: IGauge;
  yesterday: IGauge;
  last_week: IGauge;
}

type CardItem = {
  label: string;
  content: string | undefined;
  value: number | undefined;
};

const startColor = new Color("#FF4D4F");
const endColor = new Color("#52C41A");
const rangeColor = startColor.range(endColor);
const colorByScore = (score: number) => rangeColor(score / 100).toString({ format: "hex" });

const FearAndGreedIndex = ({ size }: { size?: "small" | "default" | undefined }) => {
  const t = useTranslations("home");

  const { data: response, isLoading } = useAxiosQuery<{ success: boolean; data: IStatisticGauge }>({
    url: "/v1/feed/public/market-gauge",
    method: "get",
  });
  const data = useMemo(() => response?.data, [response?.data]);

  const gaugeConfig = useMemo(
    () => ({
      score: data?.now?.value ?? 0,
      content: data?.now?.type,
      max: 100,
      title: (data?.now?.value ?? 0).toString(),
      isAutoColor: true,
    }),
    [data],
  );

  const renderCardItem = useCallback(
    (item: CardItem) => (
      <motion.div
        key={item.label}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <MyCard key={item.label} className="shadow-lg rounded-lg" styles={{ body: { padding: 8 } }}>
          <div className="flex justify-between items-center flex-wrap">
            <div className="flex flex-col gap-2">
              <Typography.Text strong>{item.label}</Typography.Text>
              <Typography.Title level={5} className="m-0 line-clamp-1" style={{ color: colorByScore(item.value ?? 0) }}>
                {item.content}
              </Typography.Title>
            </div>
            <Typography.Title level={5}>{item.value}</Typography.Title>
          </div>
        </MyCard>
      </motion.div>
    ),
    [],
  );

  const cardItems = useMemo(
    () =>
      [
        {
          label: t("Yesterday"),
          content: data?.yesterday?.type,
          value: data?.yesterday?.value,
        },
        {
          label: t("Last_week"),
          content: data?.last_week?.type,
          value: data?.last_week?.value,
        },
      ].map(renderCardItem),
    [data?.last_week?.type, data?.last_week?.value, data?.yesterday?.type, data?.yesterday?.value, renderCardItem, t],
  );

  return (
    <MyCard
      title={<Heading title={t("Fear_and_Greed_Index")} />}
      className="w-full"
      size={size || "default"}
      styles={{ body: { display: "flex", alignItems: "center", padding: 8 } }}
    >
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 3 }} className="mt-2" />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col gap-2 xl:flex-row w-full items-center justify-between"
        >
          <div className="flex-grow w-full lg:w-auto lg:flex-[0_0_65%] lg:max-w-[60%]">
            <StatusGauge {...gaugeConfig} />
          </div>
          <div className="flex flex-col gap-2 w-full xl:w-auto xl:flex-[0_0_35%] xl:max-w-[40%]">{cardItems}</div>
        </motion.div>
      )}
    </MyCard>
  );
};

export default React.memo(FearAndGreedIndex);
