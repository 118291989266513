import * as React from "react";
import type { SVGProps } from "react";

const KlardaBubbleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.7873 16.347C24.5649 16.347 27.6273 13.2846 27.6273 9.507C27.6273 5.72937 24.5649 2.66699 20.7873 2.66699C17.0096 2.66699 13.9473 5.72937 13.9473 9.507C13.9473 13.2846 17.0096 16.347 20.7873 16.347Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M8.4797 25.9204C10.7477 25.9204 12.5864 24.0818 12.5864 21.8137C12.5864 19.5457 10.7477 17.707 8.4797 17.707C6.21165 17.707 4.37305 19.5457 4.37305 21.8137C4.37305 24.0818 6.21165 25.9204 8.4797 25.9204Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.1594 29.3335C24.0446 29.3335 25.5728 27.8053 25.5728 25.9202C25.5728 24.035 24.0446 22.5068 22.1594 22.5068C20.2743 22.5068 18.7461 24.035 18.7461 25.9202C18.7461 27.8053 20.2743 29.3335 22.1594 29.3335Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default KlardaBubbleIcon;

