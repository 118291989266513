"use client";
import { Skeleton, Typography } from "antd";
import React, { useCallback, useMemo } from "react";
import { useRouter } from "next/navigation";
import classnames from "classnames";
import type { INewsResponse } from "@/models/news";
import { IMAGE_SHARE_CDN } from "@/constant/apiPath";
import { NewsFromProjectCategory, sanitizeHtmlContent } from "@/helpers";
import NewsInfo from "@/app/components/NewsInfo";
import imageBlog from "@/asset/images/image-blog.png";
import { useLanguageContext } from "@/context/LanguageContext";

interface NewsCardProps {
  news: INewsResponse | undefined;
  short?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

function NewsCard({ news, short, onClick, className, style }: NewsCardProps) {
  const router = useRouter();
  const { language } = useLanguageContext();

  const onNewsClick = useCallback(() => {
    if (news) {
      router.push(`/${language}/news/${news.id}`);
    }
  }, [language, news, router]);

  const dom = useMemo(() => {
    if (!news) {
      return (
        <Skeleton
          title={false}
          paragraph={{ rows: 4 }}
          avatar={{ size: "large", shape: "square" }}
          style={{ padding: 10 }}
        />
      );
    }

    const title = sanitizeHtmlContent(news.title || news.description || "");
    const description = sanitizeHtmlContent(news.description || news.content || "");

    const imageUrl = news?.is_klarda
      ? `${IMAGE_SHARE_CDN}${news?.thumbnail}`
      : news.thumbnail ||
        (news.klarda_categories?.includes(NewsFromProjectCategory)
          ? imageBlog
          : "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png");

    return (
      <div className="flex flex-wrap gap-4">
        <div className="w-full rounded-lg overflow-hidden">
          <div className="relative h-[150px] w-[200px]">
            <img
              src={imageUrl as string}
              alt={news.title || "img"}
              className="rounded-lg object-cover w-full h-full"
              onError={e => {
                e.currentTarget.src = "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png";
              }}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between py-2 pr-2 w-full">
          <div className="space-y-1">
            <Typography.Link title={title}>
              <Typography.Title level={5} className="line-clamp-3 m-0">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Typography.Title>
            </Typography.Link>
            {!short && (
              <Typography.Text title={description} type="secondary" className="line-clamp-3">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Typography.Text>
            )}
          </div>
          <NewsInfo news={news} />
        </div>
      </div>
    );
  }, [news, short]);

  return (
    <div
      onClick={onClick || (news ? onNewsClick : undefined)}
      className={classnames("p-2 rounded-lg cursor-pointer", className)}
      style={style}
    >
      {dom}
    </div>
  );
}

export default NewsCard;
