"use client";
import { memo } from "react";
import { Skeleton } from "antd/lib";
import { motion } from "framer-motion";
import ScrollableComponent from "@/app/components/ScrollComponent";
import type { IKlardaNewsResponse, INewsResponse, NewsResponseCDN } from "@/models/news";
import useAxiosQuery from "@/hooks/useMyQuery";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import { useTranslations } from "next-intl";
import NewsCard from "@/app/components/NewsCard";
import MyCard from "@/app/components/MyCard";

const LearnAboutCrypto = () => {
  const t = useTranslations("home");

  const { data: newsData, isLoading } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news/education`,
    method: "get",
  });

  return (
    <MyCard title={t("learn_about")}>
      <ScrollableComponent className="flex overflow-x-auto gap-1">
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} className="w-[300px]" active />)
          : (newsData?.news ?? []).map(newsItem => (
              <motion.div
                key={newsItem.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="w-[300px] mycard-hover"
              >
                <NewsCard news={newsItem as INewsResponse & IKlardaNewsResponse} />
              </motion.div>
            ))}
      </ScrollableComponent>
    </MyCard>
  );
};

export default memo(LearnAboutCrypto);
