import React, { useMemo } from "react";
import { Area } from "@ant-design/plots";
import { formatThousands } from "@/helpers";
import dayjs from "@/utils/dayjsConfig";
import { DateTimeFormat } from "@/constant";
import { useTimezoneContext } from "@/context/TimezoneContext";
import { useCurrencyContext } from "@/context/CurrencyContext";

interface ChartAreaProps {
  data: { date: string; price: number }[];
  lastPoint: { date: string; price: number };
  maxPrice: number;
  minPrice: number;
  isDown: boolean;
  durationFormat?: string;
  height?: number;
}

export const ChartArea: React.FC<ChartAreaProps> = React.memo(
  ({ lastPoint, data, maxPrice, minPrice, isDown, durationFormat = "HH:mm", height = 350 }) => {
    const { timezone } = useTimezoneContext();
    const { currentCurrency } = useCurrencyContext();

    const chartConfig = useMemo(() => {
      const annotations = [
        {
          type: "line",
          start: ["min", maxPrice],
          end: ["max", maxPrice],
          style: { stroke: "#52c41a", lineWidth: 2 },
        },
        {
          type: "line",
          start: ["min", minPrice],
          end: ["max", minPrice],
          style: { stroke: "#ff4d4f", lineWidth: 2 },
        },
        {
          type: "html",
          position: ["max", maxPrice],
          html: `<div class="annotation-tag green">${formatThousands(maxPrice, currentCurrency)}</div>`,
        },
        {
          type: "html",
          position: ["max", minPrice],
          html: `<div class="annotation-tag red">${formatThousands(minPrice, currentCurrency)}</div>`,
        },
        {
          type: "html",
          position: [lastPoint.date, lastPoint.price],
          html: `<div class="chart-point"></div>`,
        },
      ];
      return {
        data,
        xField: "date",
        yField: "price",
        // height: 330,
        autoFit: true,
        smooth: true,
        color: isDown ? "#ff4d4f" : "#52c41a",
        areaStyle: {
          fill: `l(270) 0:#ffffff 1:${isDown ? "#ff4d4f88" : "#52c41a88"}`,
        },
        xAxis: {
          tickCount: 5,
          grid: { line: { style: { stroke: "#dddfe5" } } },
          label: { formatter: (val: string) => dayjs(val).tz(timezone.utc).format(durationFormat) },
        },
        yAxis: {
          position: "right",
          min: minPrice,
          max: maxPrice,
          grid: { line: { style: { stroke: "#dddfe5" } } },
          label: {
            formatter: (val: string) => formatThousands(parseFloat(val), currentCurrency),
            offset: 15,
          },
        },
        annotations,
        tooltip: {
          showCrosshairs: true,
          shared: true,
          formatter: (datum: any) => ({
            name: "Price",
            value: formatThousands(datum.price, currentCurrency),
            title: dayjs(datum.date).tz(timezone.utc).format(DateTimeFormat),
          }),
        },
      };
    }, [
      maxPrice,
      minPrice,
      lastPoint.date,
      lastPoint.price,
      data,
      isDown,
      durationFormat,
      currentCurrency,
      timezone.utc,
    ]);

    return (
      <>
        <Area {...(chartConfig as any)} style={{ height }} />
        <style jsx global>{`
          .annotation-tag {
            position: relative;
            padding: 2px 8px;
            border-radius: 2px;
            font-weight: bold;
            color: white;
            font-size: 12px;
            display: flex;
            top: -11px;
            left: 7px;
            align-items: center;
            transform: translateX(0);
          }

          .annotation-tag:before {
            content: "";
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-right: 8px solid;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }

          .annotation-tag.green {
            background-color: #52c41a;
          }

          .annotation-tag.green:before {
            border-right-color: #52c41a;
          }

          .annotation-tag.red {
            background-color: #ff4d4f;
          }

          .annotation-tag.red:before {
            border-right-color: #ff4d4f;
          }

          .annotation-tag.orange {
            top: 5px;
            background-color: orange;
          }

          .annotation-tag.orange:before {
            border-right-color: orange;
          }
        `}</style>
      </>
    );
  },
);

ChartArea.displayName = "ChartArea";
