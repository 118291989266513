"use client";

import React from "react";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import MarketInsightCard from "@/app/components/MarketInsightCard";

const Notable = React.memo(function Notable() {
  return (
    <MarketInsightCard
      title="Notable_title"
      apiUrl={`${CLOUDFRONT_URL}/v1/market-insight/news/notable-calls-and-insight`}
      category="Notable Calls and Insight"
    />
  );
});

export default Notable;
